<template>
  <CmsLayout
    v-slot="{ refScrollWrap }"
    class="title-page"
    :class="[$store.theme, `${type}-title-page`]"
    :data-cy="`${type}-title-page`"
  >
    <main class="container-1440 title-page-inner">
      <StatusMessage
        v-if="showError && !title"
        :status="false"
        :title="getTranslation('something_went_wrong')"
        :text="getTranslation('try_again')"
        :button-text="getTranslation('back_to_vod_catalog')"
        @clickButton="goToArchiveCatalogPageWithRouter"
      />
      <template v-if="title || !isSourceAvailable">
        <VodTitleDetails v-if="type === 'vod'" class="container-1440" />
        <ArchiveTitleDetails v-else-if="type === 'archive'" class="container-1440" />
      </template>
    </main>
    <ButtonActionScrollTop :scroll-block="refScrollWrap" />
  </CmsLayout>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import Global from 'src/mixins/Global';
import { Prop, Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import BreadCrumbs from 'src/components/ui/BreadCrumbs.vue';
import logger from 'src/utils/logger';
import ArchiveTitleDetails from 'src/components/archive/ArchiveTitleDetails.vue';
import VodTitleDetails from 'src/components/vod/VodTitleDetails.vue';
import StatusMessage from 'src/components/StatusMessage.vue';
import ButtonActionScrollTop from 'src/components/ui/buttons/ButtonActionScrollTop.vue';

const log = logger(`vod-details`);

@Component({
  components: {
    BreadCrumbs,
    CmsLayout,
    ArchiveTitleDetails,
    VodTitleDetails,
    StatusMessage,
    ButtonActionScrollTop,
  },
})
export default class VodDetails extends mixins(Global) {
  loadTitlePending = false;
  showError = false;

  @Prop({ required: true })
  type!: 'vod' | 'archive';

  @Watch('$store.vod.states.sourcesLoaded')
  async onVodSourcesLoadedChange(val: boolean) {
    if (val) {
      await this.showTitleDetails();
      actions.common.showFooter(this.$store);
    }
  }

  get sourceId() {
    return this.$route.params.sourceId || 'archive';
  }

  get titleId() {
    return this.$route.params.titleId;
  }

  get title() {
    return selectors.vod.modalTitleSelector(this.$store, this.sourceId);
  }

  get isSourceAvailable() {
    return !!this.$store.vod.sources[this.sourceId];
  }

  get isPageTitleOpen() {
    return selectors.vod.isPageTitleOpenSelector(this.$store);
  }

  async serverPrefetch() {
    if (!this.isSourceAvailable) {
      return;
    }

    this.loadTitlePending = false;
    await actions.vod.loadTitleVod(this.$store, this.sourceId, this.titleId);
    await actions.vod.loadSourceCategories(this.$store, this.sourceId);
    await actions.tvChannels.loadChannelsAndGenres(this.$store);

    if (!this.isPageTitleOpen) {
      await this.showTitleDetails();
    }
  }

  async mounted() {
    if (!this.isSourceAvailable) {
      return;
    }

    if (!this.isPageTitleOpen) {
      await this.showTitleDetails();
    }

    actions.common.showFooter(this.$store);

    window.onpopstate = async () => {
      if (this.isPageTitleOpen && this.titleId) {
        await this.showTitleDetails();
      } else {
        actions.vod.hideTitleDetails(this.$store, this.sourceId, this.titleId);
      }
    };
  }

  beforeDestroy() {
    actions.vod.setIsPageTitleOpen(this.$store, false);
    actions.vod.hideTitleDetails(this.$store, this.sourceId, this.titleId);
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  async showTitleDetails() {
    actions.vod.setIsPageTitleOpen(this.$store, true);

    if (!this.isSourceAvailable) {
      return;
    }

    this.loadTitlePending = true;
    await actions.vod
      .showTitleDetails(this.$store, this.sourceId, this.titleId, false)
      .finally(() => {
        this.loadTitlePending = false;
      })
      .catch((err: any) => {
        this.showError = true;
        log.error('Unable to load title', err);
      });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/side-and-bottom-padding';

.title-page {
  margin-top: 64px;

  .title-details {
    mask-image: none;
  }

  .loader-spinner {
    flex-grow: 1;
  }

  .status-message {
    padding-top: 64px;

    @include mobile {
      padding-top: 32px;
    }
  }

  .title-details {
    &::v-deep {
      .with-side-and-bottom-padding {
        @include side-and-bottom-padding;
      }

      .top {
        margin-top: 0;
      }
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    .title-page-inner {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    }

    .container-1440 {
      background-color: var(--c-light-100);
    }
  }

  &.dark {
    .title-page-inner {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);
    }

    .container-1440 {
      background-color: var(--c-dark-150);
    }
  }
}
</style>
