<template>
  <VodDetails v-if="!isError404" type="archive" />

  <Error404Page v-else />
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import VodDetails from 'src/components/vod/VodDetails.vue';
import { actions } from 'src/store/actions';
import Vod from 'src/mixins/Vod';
import Seo from 'src/mixins/Seo';
import Global from 'src/mixins/Global';
import { transliterate } from 'src/utils/language';
import random from 'lodash/random';
import Error404Page from 'src/pages/error/Error404Page.vue';

@Component({
  components: {
    Error404Page,
    VodDetails,
  },
})
export default class ArchiveTitlePage extends mixins(Vod, Seo, Global) {
  isError404 = false;

  async serverPrefetch() {
    const { titleId } = this.$route.params;
    if (this.$store.vod.sources.archive && titleId) {
      await actions.vod.loadTitleVod(this.$store, 'archive', titleId);

      if (this.title) {
        this.initMeta();
        actions.seo.addBreadcrumbProduct(this.$store, this.breadcrumbProduct);
        actions.seo.addBreadcrumbs(this.$store, this.seoBreadcrumbs);
      } else {
        this.isError404 = true;
      }
    }
  }

  getMetaInfo() {
    if (!this.title) {
      return {
        title: '',
        meta: [],
      };
    }

    const archiveTitle = this.$store.vod?.sources['archive']?.fullTitles[this.titleIdFromParams];
    const typeSingular = archiveTitle?.preview?.categories?.[0]
      ? `${this.getTranslation(
          `category_${transliterate(archiveTitle?.preview?.categories?.[0]?.title || '')}_singular`
        )}`
      : '';
    const typeAccusative = archiveTitle?.preview?.categories?.[0]
      ? this.getTranslation(
          `category_${transliterate(
            archiveTitle?.preview?.categories?.[0]?.title || ''
          )}_accusative`
        )
      : '';
    const title = archiveTitle?.preview?.title || '';
    const year = archiveTitle?.preview?.years?.[0];
    const titleDescriptionFirstSentence = archiveTitle?.details?.description.split('. ')?.[0];

    const metaTitle = this.getTranslation('archive_meta_title')
      .replace(/%titleName%/g, title)
      .replace(/%year%/g, year ? `(${year})` : '')
      .replace(/%titleType%/g, typeSingular);

    const metaDescription = this.getTranslation('archive_meta_description')
      .replace(/%titleType%/g, typeAccusative)
      .replace(/%titleName%/g, title)
      .replace(/%first_sentence%/g, titleDescriptionFirstSentence || '')
      .replace(
        /%titleGenres%/g,
        archiveTitle?.preview?.genres?.map((item) => item.title).join(',') || ''
      );

    return {
      title: metaTitle,
      meta: [
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: this.getTranslation('archive_meta_keywords')
            .replace(/%titleName%/g, title)
            .replace(/%titleType%/g, typeSingular),
        },
        {
          name: 'og:description',
          content: metaDescription,
        },
        {
          name: 'og:title',
          content: metaTitle,
        },
        {
          name: 'og:image',
          content: archiveTitle?.preview?.posters?.[0]?.path,
        },
      ],
    };
  }

  get seoBreadcrumbs() {
    return [
      { name: this.getTranslation('breadcrumb_smotreshka'), link: '/' },
      { name: this.getTranslation('breadcrumb_archive'), link: '/archive' },
      {
        name:
          this.$store.vod.sources['archive']?.fullTitles[this.titleIdFromParams]?.preview?.title ||
          '',
        link: `/archive/${this.titleIdFromParams}`,
      },
    ];
  }

  get breadcrumbProduct() {
    return {
      name: this.title?.preview?.title || '',
      image: this.title?.preview?.posters?.[0]?.path,
      description: this.title?.details?.description,
      brand: this.getTranslation('breadcrumb_smotreshka'),
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue:
          this.title?.preview?.ratingKp?.toFixed(1) ||
          this.title?.preview?.ratingImdb?.toFixed(1) ||
          '',
        reviewCount: random(10, 1000).toString(),
        bestRating: '10',
      },
    };
  }
}
</script>
